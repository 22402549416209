@import "src/variables.scss";

.game7 {

    &.game {
        background-color: rgb(255, 255, 255);
        background-size: cover;
    }

    .digSiteContainer {
        margin: 20px;
        padding: 20px 5px;

        @include sm {
            margin-top: 0;
            margin-bottom: 100px;
        }
    }

    .digSite {
        display: grid;
        $factor: 1.2;
        grid-template-columns: repeat(4, 20vmin * $factor);
        grid-template-rows: repeat(3, 16.326vmin * $factor);
        
        @include lg {
            grid-template-columns: repeat(4, 20vmin);
            grid-template-rows: repeat(3, 16.326vmin);
        }
        
        @include md {
            $factor: 1.2;
            grid-template-columns: repeat(3, 20vmin * $factor);
            grid-template-rows: repeat(4, 16.326vmin * $factor);
        }

        .digContainer {
            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;

            &:not(.uncovered) {
                cursor: pointer;
            }

            .dig {
                position: absolute;
                width: 133.33333%;
                height: 133.33333%;

                > * {
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }

            .digged {
    
                &.wrong {

                    &.fade-enter-active,
                    &.fade-enter-done {

                        .object {
                            opacity: .8;
                        }
                    }
                }
            }

            .shovel {
                position: absolute;
                width: 60%;
                height: 60%;
                transform: rotate3d(0, 0, 1, -15deg);

                &.digging {
                    animation: dig 500ms linear;
                }
            }

            .objectContainer {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 43.26%;
                height: 53%;

                .object {
                    border-radius: 100%;
                    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
                }

                svg {
                    position: absolute;
                    bottom: -10px;
                    right: -10px;
                }
            }
        }
    }

    .foundInfo {
        position: fixed;
        bottom: 40px;
        right: 40px;

        @include sm {
            flex-wrap: wrap;

            p {
                width: 100%;
                margin: 12px;
                text-align: left;
            }
        }

        font-family: 'Quicksand', sans-serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: right;

        display: flex;
        align-items: center;

        img {
            padding: 4px;
            margin-left: 12px;

            width: 68px;
            height: 68px;

            background: #FFFFFF;
            border-radius: 12px;

            transition: all 500ms ease-out;
            box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);

            &:not(.complete) {
                opacity: .5;
            }
        }
    }

    .uncoveredInfo {
        z-index: 25;
        
        h2 {
            font-size: 22px;
            line-height: 34px;
            letter-spacing: 0.1em;

            align-self: stretch;
        }

        .foundImage {
            display: flex;
            justify-content: center;
            margin: 24px 0;

            img {
                width: 68px;
                height: 68px;
                border-radius: 12px;

                &.complete {
                    border: 1px solid rgba(0, 0, 0, 0.35);
                }

                &:not(.complete) {
                    border: 1px dashed rgba(0, 0, 0, 0.35);
                    opacity: .5;
                }

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        .helpBox {

            .textContainer {
                flex-grow: 1;
            }
        }

        @include sm {
            .helpBox {
                height: 100%;

                .picture {
                    max-height: 200px;
                }
            }
        }
    }
}

@keyframes dig {
    0% {
        transform: rotate3d(0, 0, 1, -15deg);
    }
    50% {
        transform: rotate3d(0, 0, 1, 0deg);
    }
    100% {
        transform: rotate3d(0, 0, 1, -15deg);
    }
}