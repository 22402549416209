@import "src/variables.scss";

.game.page.interviews {

    .swiper {

        img {
            max-height: 282px;
        }

        .swiper-slide {
            width: min(80vw, 400px);
        }

        .type {
            background-color: #99ca3c;
        }

        .details {
            background-color: #90be3a;
            white-space: normal;
        }

        .description {
            height: 110px;
        }
    }
}