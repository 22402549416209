@import "src/variables.scss";

* {
    user-select: none;
    box-sizing: border-box;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    --swiper-pagination-color: #f7931e;
    --swiper-theme-color: #f7931e;
}

::-moz-selection {
    color: #fff;
    background: #f7931e;
}

::selection {
    color: #fff;
    background: #f7931e;
}

::backdrop
{
    background-color: #fff;
}

body {
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: 'Quicksand', sans-serif;
}

video {
    background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
}

#root {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.1em;
    white-space: pre-line;
    overflow: hidden;
}

.game {
    height: var(--vh);
    width: 100vw;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: 'Amatic SC', sans-serif;
    font-size: 22px;
    font-weight: 700;
    line-height: 24px;

    @include md {
        font-size: 19px;
        line-height: 21px;
    }

    @include sm {
        font-size: 16px;
        line-height: 18px;
    }

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: #efefef;

    &.page {
        flex-direction: column;
        justify-content: flex-start;

        &.info {
            font-family: 'Quicksand', sans-serif;
            font-weight: 400;

            h1 {
                font-family: 'Quicksand', sans-serif;
            }

            .main {
                flex-direction: column;

                @include md {
                    font-size: 18px;
                }

                @media (min-width: 1270px) {
                    height: calc(100% - 100px);
                    margin-bottom: 0;
                }
            }
        }

        h1, h2, h3, h4, h5, h6 {
            word-break: break-word;
            word-wrap: break-word;
            hyphens: auto;
        }

        h1 {
            font-family: 'Amatic SC', sans-serif;
            font-size: 40px;

            margin-top: 12px;
            padding: 0 95px;

            letter-spacing: 0em;
            line-height: 68px;
            width: 100%;
            text-align: center;

            @include sm {
                font-size: 32px;
                text-align: left;

                &:not(&.has-right-button) {
                    padding-right: 0;
                }

                &.has-right-button {
                    width: calc(100% - 190px);
                    width: 100%;
                }
            }
        }

        .main {
            margin: 30px 0 80px;
            display: flex;
            position: relative;
            align-items: center;
            overflow: visible;
            height: calc(100% - 200px);

            @include md {
                height: calc(var(--vh) - 200px);
                margin-bottom: calc(100vh - var(--vh) + 100px);
            }
        }

        .scroll {
            width: 100vw;
            overflow-y: auto;

            > div {
                width: 800px;
                max-width: 80vw;
                padding-bottom: 80px;
                margin: auto;
                line-height: normal;

                section {

                    * {
                        user-select: text;
                        max-width: 100%;
                    }

                    &:not(:last-child) {
                        margin-bottom: 50px;
                    }

                    h2 {
                        font-size: 30px;
                        margin-bottom: 10px;

                        @include md {
                            font-size: 24px;
                        }
                    }

                    h3 {
                        font-size: 24px;
                        margin-bottom: 10px;

                        @include md {
                            font-size: 20px;
                        }
                    }

                    p {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    height: var(--vh);
    width: 100vw;
    overflow: hidden;
}

a {
    text-decoration: none;
    color: inherit;
    font-weight: 700;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    padding: 6px 12px;
    border-radius: 12px;
    background: #0000001A;
}

button {
    background-color: #fff;
    border: 0;
    cursor: pointer;
    padding: 0;
}

img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

p {
    margin: 0;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.bold {
    font-weight: 600;
    text-transform: uppercase;
}

.endGameContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;

    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: auto;
        height: auto;
        max-width: 90%;
        max-height: 90%;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.35);
    }
}

.CookieConsent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    flex-direction: column;
    max-width: 500px;
    height: auto;
    border-radius: 10px;
    background: rgba(43, 55, 59, 0.8);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3), 0px 5px 100px rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(15px);
    padding: 20px;
    color: #fff;
    z-index: 1000;

    .content {

        a {
            margin-left: 5px;

            &::after {
                content: " →";
            }
        }
    }

    .button-wrapper {
        display: flex;
        margin-top: 15px;

        button {
            display: flex;
            justify-content: center;
            padding: 15px;

            font-size: 100%;
            height: auto;
            transition: box-shadow 0.2s ease-in-out;

            &:hover {
                box-shadow: 0px 5px 10px rgba(0, 255, 0, 0.3), 0px 0px 20px rgba(0, 255, 0, 0.3);
            }

            &.decline {
                margin-right: 10px;
                background-color: rgb(222, 0, 0);

                &:hover {
                    box-shadow: 0px 5px 10px rgba(255, 0, 0, 0.3), 0px 0px 20px rgba(255, 0, 0, 0.3);
                }
            }
        }
    }
}