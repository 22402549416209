@import "src/variables.scss";

.game8 {

    &.game {
        background-color: rgb(255, 255, 255);
    }

    button.complete {
        position: absolute;
        top: 12px;
        right: 68px + 12px * 2;
        width: 190px;
        height: 68px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        font-family: 'Quicksand', sans-serif;
        font-size: 22px;
        font-weight: 700;
        padding: 0 11px 0 24px;
        color: #ffffff;
        background: #8EC165;
        margin: 0;
        
        img {
            width: 60px;
            height: 60px;
            animation: moveLeftAndBack 5s ease-in-out infinite;
        }
    }

    .button-container {
        gap: 17px;
        transition: all 400ms ease-in-out;

        padding: 4vw;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        display: grid;
        grid-template-columns: repeat(2, 10vw) 0 repeat(2, 10vw);
        grid-template-rows: repeat(3, 10vw);
        grid-template-areas: 
            ". . droppable . ."
            ". . droppable . ."
            ". . droppable . ."; 

        &.space {
            grid-template-columns: repeat(2, 10vw) 20vw repeat(2, 10vw);
        }

        @include lg {
            grid-template-columns: repeat(2, 15vw) 0 repeat(2, 15vw);
            grid-template-rows: repeat(3, 15vw);

            &.space {
                grid-template-columns: repeat(2, 15vw) 20vw repeat(2, 15vw);
            }
        }

        @include sm {
            background-position: top center;
            background-size: 142vw;
            gap: 2vw;
            grid-template-columns: repeat(4, 20vw) !important;
            grid-template-rows: 80vw repeat(3, 20vw) !important;
            grid-template-areas: 
                "droppable droppable droppable droppable"
                ". . . ."
                ". . . ."
                ". . . .";
        }

        .droppable {
            grid-area: droppable;

            .stage {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                width: 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;

                .CrossfadeImage {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        button {
            display: flex;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 100%;
            backdrop-filter: blur(4px);
            transition: all 0.3s ease-in-out;

            &.dragging {
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
                z-index: 10;
            }

            &.draggable {
                // border: 2px dashed #00000033;
                background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%2300000033' stroke-width='5' stroke-dasharray='11' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
            }

            &.disabled {
                filter: grayscale(1);
                pointer-events: none;
            }
        }
    }
}