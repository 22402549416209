.mail {
    
    &.game {
        overflow-y: auto;
    }

    .main {
        overflow-y: scroll;
    }

    .sent,
    form {
        position: relative;
        width: 800px;
        max-width: 80vw;
        height: 100%;
        max-height: 550px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        text-shadow: 2px 2px 0 #fff,
            2px -2px 0 #fff,
            -2px 2px 0 #fff,
            -2px -2px 0 #fff,
            2px 0px 0 #fff,
            0px 2px 0 #fff,
            -2px 0px 0 #fff,
            0px -2px 0 #fff;

        .fill {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        }
    }

    input:not([type="checkbox"]),
    select,
    textarea {
        font-family: 'Quicksand', sans-serif;
        width: 100%;
        padding: 9px;
        border: 1px solid #ccc;
        outline: 0 solid #8ec165;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 6px;
        box-sizing: border-box;
        margin-top: 10px;
        margin-bottom: 25px;
        transition: all ease-out .2s;
        user-select: auto;

        &:focus,
        &:active {
            // padding: 7px;
            border-color: #8ec165;
            outline-width: 3px;
        }

        &.error {
            color: rgb(222, 0, 0);
            border-color: rgb(222, 0, 0);
            outline-color: rgb(222, 0, 0);
            outline-width: 2px;
        }
    }

    .checkbox {
        display: flex;
        align-items: center;
        margin-bottom: 25px;

        input {
            margin-right: 10px;
        }

        &.error {
            color: rgb(222, 0, 0);

            input {
                color: rgb(222, 0, 0);
                background-color: rgb(222, 0, 0);
            }
        }
    }

    textarea {
        flex-grow: 1;
        resize: none;
    }

    .status {
        height: 55px;

        button.button {
            font-size: 17px;
            width: auto;
            height: 100%;
            margin: 0;
            float: right;

            img {
                height: 50px;
                width: 50px;
                margin-left: 10px;
            }

            .rotate {
                animation: rotateLoop 1500ms linear infinite;
                margin-left: -10px;
            }
        }
    }

    .sent {
        flex-direction: row;
        align-items: center;

        img {
            height: 30px;
            width: 30px;
            margin-right: 20px;
        }

        span {
            width: 60%;
        }
    }

    #potter {
        display: none;
        visibility: hidden;
    }

    .pals {
        position: absolute;
        right: -15%;
        top: 0;
        width: 40%;
        min-width: 238px;

        img {
            max-width: 357px;
            max-height: 536px;
        }
    }
}

@keyframes rotateLoop {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-359deg);
    }
}