@import "src/variables.scss";

.errorContainer {
    position: fixed;
    top: 50px;
    right: 50px;
    width: 400px;
    max-width: calc(100vw - 100px);
    border-radius: 8px;
    border: 2px solid rgb(200, 0, 0);
    background-color: rgba(218, 38, 38, 0.6);
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
    color: rgb(255, 255, 255);
    padding: 10px;
    font-family: 'Quicksand', sans-serif;
    font-size: .9rem;
    // blur background
    backdrop-filter: blur(10px);
    z-index: 100;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;

    &.show {
        opacity: 1;
    }
}

.modalContainer {
    z-index: 100;

    $space: 24px;

    .modal {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        
        background-color: #fff;
        width: 640px;
        border-radius: 12px;
        z-index: 20;

        @include md {
            position: fixed;
            left: 5%;
            top: 5%;
            right: 5%;
            bottom: 5%;

            width: 90%;
            height: 90%;

            transform: none;
            overflow-y: auto;
        }
    
        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
    
            h2 {
                color: #797979;
                font-size: 22px;
                letter-spacing: 0.1em;
                padding-left: $space;
                margin: 15px 0;
            }
    
            .close,
            svg {
                width: 68px;
                height: 68px;
    
                background-color: transparent;
            }
        }
    
        .content {
            padding: $space;

            > *:not(:last-child) {
                margin-bottom: $space;
            }

            img {

                &.center {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}