@import "src/variables.scss";

.intro {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    height: var(--vh);
    width: 100vw;

    .textContainer {
        position: fixed;
        right: 24px;
        top: 24px;

        button.mute {
            background: transparent;
        }
    }

    .background-video {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 100%;
        z-index: -1;
    }
}

$button-size: 220px;
$button-spacing: 40px;

.bottom {
    margin: 25px auto;
    width: calc(100% - 40px * 2);
    max-width: ($button-size + $button-spacing) * 5 - $button-spacing;

    @media (max-width: 1340px) {
        margin: 25px 40px;
    }

    @include sm {
        width: calc(100% - 12px * 2);
        margin: 25px 12px;
    }

    .footer {
        display: flex;
        justify-content: space-between;

        @include md {
            display: block;

            .links span,
            .links a {
                display: block;
                margin-bottom: 10px;
            }

            .info {

                .button {
                    float: right;
                    margin-top: -35px;
                    margin-left: 80px;
                }
            }
        }
    }

    .links {
        > * {
            margin-right: 25px;
        }
    }
}

.buttons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-template-rows: repeat(1, $button-size);
    gap: 40px;

    @include lg {
        grid-template-columns: repeat(3, minmax(100px, 1fr));
        grid-template-rows: repeat(2, 140px);
        gap: 20px;

        > a:nth-child(1) { grid-area: 1 / 1 / 2 / 3; }
    }

    @include md {
        gap: 6px;
    }

    margin-bottom: 25px;
    justify-content: center;
    align-items: center;

    a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;
        border-radius: 24px;
        background: #FFFFFFCC;
        backdrop-filter: blur(10px);
        box-shadow: 0px 10px 20px 0px #00000033;
        height: 100%;

        img {
            width: 96px;
            height: 96px;

            @include lg {
                width: 80px;
                height: 80px;
            }
        }

        .title {
            font-family: 'Quicksand', sans-serif;
            font-size: 18px;
            font-weight: 700;

            @include lg {
                font-size: 12px;
            }

            text-align: center;
            text-transform: uppercase;
            position: absolute;
            bottom: 18px;
            padding: 0 10px;
            width: 100%;
        }
    }
}