.logos {
    position: fixed;
    left: 24px;
    bottom: 24px;

    display: flex;
    align-items: center;
    height: 50px;

    .intro & {
        top: 12px;
    }

    .game & {
        animation: 1s slideIn forwards;
        overflow: hidden;
    }

    .vmLogo {
        width: 67px;
        height: 32px;
    }

    .vmTitle {
        width: 78px;
        height: 32px;
    }

    .vlmv {
        margin-left: 10px;
        width: 50px;
        height: 50px;
    }

    > div {
        display: flex;
        align-items: center;
        height: 100%;

        margin-right: 10px;

        &:first-child {
            height: 32px;

            > :first-child {
                width: 67px;
                height: 32px;
            }
        }
    }
}

@keyframes slideIn {
    from {
        bottom: -50px;
    }

    to {
        bottom: 24px;
    }
}