@import "src/variables.scss";

.map {
    flex-direction: column;

    &.game {
        background-size: auto 840px;
    }

    .vm-logo {
        display: block;
        margin-left: 3.3%;
        width: 26%;
        height: auto;
    }

    .grid {
        @supports (aspect-ratio: 1/1) {
            max-width: 85vw;
            max-height: 90vh;
        }
        @supports not (aspect-ratio: 1/1) {
            padding: 2vw 5vh;
        }
        @include aspect-ratio(100, 126);
    }
}

.map-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);
    // grid-template-areas:
    // ".  .  g11 .  g10"
    // ".  g1 .  g9 ."
    // "g2 .  g8 .  ."
    // ".  .  .  .  g7"
    // "g3 .  .  .  g6"
    // ".  g4 .  g5 .";

    grid-template-areas:
    ".  .  g11 .  g10"
    ".  g1 .  g9 ."
    "g2 .  g8 .  ."
    ".  .  .  .  g7"
    "g4 .  .  g6 ."
    ".  .  g5 .  .";

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    a {
        max-height: 125px;
        max-width: 125px;
    }

    img {
        width: 100%;
        height: 100%;
        transition: .2s;

        &:hover {
            transform: translateY(-2px);
            filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
        }
    }

    @for $i from 1 through 11 {
        .game_#{$i} {
            grid-area: g#{$i};
        }
    }
}