@use "sass:math";
@import "src/variables.scss";

.game6 {

    &.game {
        background-color: rgb(255, 255, 255);
    }

    @media (max-width: 600px) {
        .container {
            align-items: flex-start;

            .sheet .clef {
                width: 12.5vw;
            }

            .keys {
                gap: 4vw;
                background-color: transparent;
            }
        }
    }

    $notes: (
        'C4'  40px #9B2323,
        'D4'  29px #CB9B4A,
        'E4' 18px #99239B,
        'F4'  7px #198A19,
        'G4'  -4px #2944A5,
        'A4'  -15px #29A596
    );

    $spacing: 240px;

    @each $note, $offset, $color in $notes {
        .sheet {
            .note-#{$note} {
                transform: translateY($offset);
            }
        }

        .note-#{$note} {
            fill: $color;
            stroke: $color;

            &::after,
            div {
                background-color: $color;
            }

            &::after {
                border-style: solid;
                border-color: $color;
            }
        }

        .keys .note-#{$note} {
            border: 2px solid $color;

            &.active {
                background-color: $color;
            }
        }
    }

    .sheet {
        position: relative;
        display: flex;
        align-items: center;

        height: 200px;
        width: 600px;
        margin-bottom: 50px;

        .beat_2-4 path,
        .beat_4-4 path {
            fill: transparent;
        }

        .clef {
            width: 75px;
            margin: 8px;
        }
        
        .staffContainer {
            position: relative;
            flex: 100% 1 0;
            height: 100%;
            overflow: hidden;
        }

        .current {
            position: absolute;
            top: 0;
            left: 0;

            width: 50px;
            height: 100%;

            border: 1px dashed rgba(0, 0, 0, 0.35);
            border-radius: 12px;
        }

        .staff {
            position: relative;
            height: 100%;
            display: flex;
            align-items: center;
            transition: transform 0.1s linear;

            > svg {
                flex-grow: 0;
                flex-shrink: 0;
                height: 150px;
                width: 30px;

                &:first-child {
                    margin-left: 9px;
                }

                &.beat_1-4 {
                    margin-right: math.div($spacing, 4) - 30px;
                }

                &.beat_2-4 {
                    margin-right: math.div($spacing, 2) - 30px;
                }

                &.beat_4-4 {
                    margin-right: $spacing - 30px;
                }

                &.fade {
                    margin-top: -35px;
                    opacity: 0;
                    transition: all 0.2s ease-out;
                }
            }

            .beatSpans {
                display: flex;

                position: absolute;
                top: 54px;
                height: 92px;
                box-sizing: border-box;

                > div {
                    height: 100%;
                    margin-left: $spacing - 4px;

                    flex-basis: 4px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    background-color: rgba(0, 0, 0, 0.1);
                }
            }
        }

        .ledgerLines {
            position: absolute;
            width: 100%;

            > div {
                height: 4px;
                margin: 18px 0;

                background-color: rgba(0, 0, 0, 0.1);
                border-radius: 2px;
            }
        }
    }

    .keys {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 24px;
        max-width: 100vw;

        background: rgb(255, 255, 255);
        border-radius: 24px;

        button {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            touch-action: manipulation;
            
            height: 180px;
            width: 60px;
            padding: 8px 0;
            margin-right: 24px;
            border-radius: 24px;
            background-color: rgb(255, 255, 255);
            transition: background-color 200ms;

            @include sm {
                margin-right: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            div {
                font-family: 'Quicksand', sans-serif;
                font-weight: 700;
                line-height: 40px;
                color: rgba(255, 255, 255, 0.5);
                pointer-events: none;

                width: 40px;
                border-radius: 100%;

                @include sm {
                    width: 100%;
                }
            }

            &:hover {
                background-color: rgba(0, 0, 0, 0.08);
            }
        }
    }
}