@use "sass:math";
@import "src/variables.scss";

.endscreen {
    position: absolute;
    top: 0;
    z-index: 100;

    .grid {
        display: flex;
        flex-wrap: wrap;
        max-height: 80vh;
        max-width: 60vw;
        box-sizing: border-box;
    }

    .text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 45%;
        margin-right: 5%;

        @include md {
            margin-bottom: 30px;
            margin-right: 0;
            width: 100%;
        }

        h1 {
            font-size: 1em;
            line-height: 1.545em;
            letter-spacing: 0.1em;
            margin-bottom: .8em;
        }

        img {
            width: 100%;
            height: auto;

            @include md {
                display: none;
            }
        }
    }

    .book-desktop {
        display: none;

        @include md {
            display: block;
            margin-top: 30px;
        }
    }

    .book-video {
        position: relative;
        overflow: hidden;
        max-width: 50%;
        max-height: 100%;

        @include md {
            width: 100%;
            max-width: 100%;
            height: math.div(9, 16)  * 100%;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23fff' d='M356.5 235.5c7.1 4.4 11.5 12.1 11.5 20.5s-4.4 16.1-11.5 20.5l-144 88c-7.4 4.5-16.7 4.7-24.2.4-7.6-4.2-12.3-12.2-12.3-20.9V168c0-8.7 4.7-16.7 12.3-20.9 7.5-4.3 16.8-5 24.2.4l144 88z' /%3E%3Cpath fill='%23fff' d='M512 256c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256zm-336-88v176c0 8.7 4.7 16.7 12.3 20.9 7.5 4.3 16.8 4.1 24.2-.4l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-5.4-16.7-4.7-24.2-.4-7.6 4.2-12.3 12.2-12.3 20.9z' opacity='.4' /%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50%;
            background-color: rgba(0, 0, 0, 0.5);

            transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
            pointer-events: none;

            opacity: 1;
            transform: scale(1);
        }

        &.playing {
            &::after {
                opacity: 0;
                transform: scale(1.5);
            }
        }

        video {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}