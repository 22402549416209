@use "sass:math";
@import "src/variables.scss";

.game5 {

    &.game {
        background-color: rgb(255, 255, 255);
    }

    .placementArea {
        display: flex;

        .spot {
            display: flex;
            flex-direction: column;
            align-items: center;

            .person {
                width: 25vmin;
                height: 32.2vmin;

                @include md {
                    width: 30vmin;
                    height: 38.6vmin;
                }
            }
    
            .slot {
                width: 14vmin;
                height: 19.2vmin;

                @include md {
                    width: 20vmin;
                    height: 28.2vmin;
                }
            }
        }

        .droppable {
            position: relative;
            transition-property: opacity, background-color, border-radius;
            transition-delay: ease-out;
            transition-duration: 500ms;

            img {
                pointer-events: none;
            }
        }

        .placedCoin {
            position: absolute;
            left: 0;
            top: 10%;
            width: 100%;
            height: 40%;
        }
    }

    .coinContainer {
        display: flex;
        justify-items: center;
        align-items: center;

        .spot {
            margin: 20px;
            width: 12vmin;
            height: 12vmin;

            @include md {
                width: 16vmin;
                height: 16vmin;
            }
        }

        .draggable {
            width: 100%;
            height: 100%;
            padding: 0;
            align-items: center;
            cursor: pointer;

            background-color: transparent;

            transition-property: transform;
            transition-delay: ease-out;
            transition-duration: 500ms;
        }
    }

    .modal {

        .content {

            img.center {
                width: 25vmin;
                max-width: 400px;

                @include sm {
                    width: 60vmin;
                }
            }
        }
    }
}