@use "sass:math";
@import "src/variables.scss";

.game1 {

    &.game {
        background-color: #ffffff;
        background-size: contain;
    }

    .placementArea {
        position: relative;
        @include aspect-ratio(4, 3);

        @include md {
            @include aspect-ratio(1, 1);
        }

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        .spot {
            position: absolute;
            width: 0;
            height: 0;

            &:nth-child(1) { left: 36.7%; top: 40.3% }
            &:nth-child(2) { left: 44.5%; top: 42.7% }
            &:nth-child(3) { left: 51.5%; top: 45% }
            &:nth-child(4) { left: 58.3%; top: 47.3% }
            &:nth-child(5) { left: 65.5%; top: 51% }

            @include md {
                &:nth-child(1) { left: 33.7%; top: 29.5% }
                &:nth-child(2) { left: 46.3%; top: 32.4% }
                &:nth-child(3) { left: 57.9%; top: 34.9% }
                &:nth-child(4) { left: 69.1%; top: 38.2% }
                &:nth-child(5) { left: 80.9%; top: 42.9% }
            }
        }

        .droppable {
            position: absolute;
            transition-property: opacity, background-color, border-radius;
            transition-delay: ease-out;
            transition-duration: 500ms;

            &:empty {
                $size: 37px;
                left: math.div(-$size, 2);
                top: math.div(-$size, 2);
                width: $size;
                height: $size;
    
                background-color: #E8E8E9;
                border: 1px dashed #000000;
                box-sizing: border-box;
                border-radius: 100%;
                
                @include sm {
                    opacity: 0 !important;
                }
            }

            &:not(:empty) {
                $size: 12vh;
                
                left: math.div(-$size, 2);
                top: math.div(-$size, 2);
                width: $size;
                height: $size;

                @include md {
                    $size: 15vw;
                
                    left: math.div(-$size, 2);
                    top: math.div(-$size, 2);
                    width: $size;
                    height: $size;
                }
                box-sizing: border-box;
            }
        }
    }

    .letterContainer {
        display: grid;
        
        justify-items: center;
        align-items: center;

        margin: 0 60px 100px;
        grid-template-columns: repeat(5, 140px);
        grid-template-rows: repeat(1, 140px);

        position: absolute;
        bottom: 0;

        @include md {
            // margin: 3px 10px;
            grid-template-columns: repeat(6, 50px);
            grid-template-rows: repeat(2, 83px);
        }

        .spot {
            width: 100px;
            height: 100px;

            @include md {
                width: 80px;
                height: 80px;

                &:nth-child(1) { grid-area: 1 / 1 / 2 / 3; }
                &:nth-child(2) { grid-area: 1 / 3 / 2 / 5; }
                &:nth-child(3) { grid-area: 1 / 5 / 2 / 7; }
                &:nth-child(4) { grid-area: 2 / 2 / 3 / 4; }
                &:nth-child(5) { grid-area: 2 / 4 / 3 / 6; } 
            }
        }

        .draggable {
            display: flex;
            width: 100%;
            height: 100%;
            padding: 0;
            align-items: center;
            cursor: pointer;
            border-radius: 100%;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(10px);

            transition-property: box-shadow, transform;
            transition-delay: ease-out;
            transition-duration: 500ms;

            &.dragging {
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
            }
        }
    }
}