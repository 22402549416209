@import "src/variables.scss";

.game4 {
    background-position: center;
    background-repeat: no-repeat;

    // $card-width: 120px;
    // $card-height: 180px;
    // $card-width: 10vw;
    $card-width: 14.4vh;
    $card-width-md: 15vw;
    $card-width-sm: 20vw;

    $border-radius: 12px;

    &.game {
        background-color: #ffffff;
    }

    .container {
        display: grid;
        grid-template-columns: repeat(6, $card-width);
        place-items: center;
        gap: 1.65vw;
        transition: 1s;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);

        justify-content: center;
        align-content: center;

        @include md {
            grid-template-columns: repeat(5, $card-width-md);
        }

        @include sm {
            grid-template-columns: repeat(4, $card-width-sm);
        }
    }


    .card {
        height: $card-width * 1.5;
        width: $card-width;
        border-radius: $border-radius;
        box-shadow: 0px 10px 20px 0px #00000059;
        transition: .5s;
        transform-style: preserve-3d;
        position: relative;
        cursor: pointer;
        opacity: 1;

        @media (min-width: #{$screen-sm-min + 1}) and (max-width: #{$screen-md-min}) {
            height: $card-width-md * 1.5;
            width: $card-width-md;

            &:nth-last-child(3) {
                grid-column-end: 3;
            }
        }

        @include sm {
            height: $card-width-sm * 1.5;
            width: $card-width-sm;

            &:nth-last-child(2) {
                grid-column-end: 3;
            }
        }

        &:hover {
            box-shadow: 0px 10px 20px 0px #00000079;
            transform: scale(0.95);
        }

        .card-face {
            border-radius: $border-radius;
            backface-visibility: hidden;
            position: absolute;
            width: 100%;
            height: 100%;

            img {
                border-radius: $border-radius;
                object-fit: cover;
            }

            &.card-back-face {
                transform: rotateY(180deg);
            }
        }

        &.is-inactive {
            transform: rotateY(180deg);
        }

        &.is-flipped {
            transform: rotateY(180deg);
        }
    }
}