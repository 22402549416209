@import "src/variables.scss";

.cards {

    .swiper {
        
        .swiper-slide {
            width: min(80vw, 400px);
            border-radius: 20px;
            background: #FFFFFF;

            figure {
                margin: 0;
                padding: 12px;
                border-radius: 20px;
                transition: box-shadow .4s;
            }

            &.swiper-slide-active figure,
            &:hover figure {
                box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.2), inset 0px -2px 1px rgba(0, 0, 0, 0.2);
            }
        }

        img {
            max-height: 240px;
            border-radius: 12px;
            object-fit: cover;
        }

        figcaption {
            margin-top: 12px;
            font-family: 'Quicksand', sans-serif;
            color: rgb(94, 94, 94);
            background: rgba(0, 0, 0, 0.05);
            border-radius: 12px;
            
            > * {
                padding: 8px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .type {
            position: relative;
            line-height: 34px;
            letter-spacing: 0.1em;

            color: #ffffff;
            background: #ff7a01;
            border-radius: 12px 12px 0px 0px;

            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-right: 0;

            @include sm {
                flex-direction: column;
                align-items: flex-start;
            }

            div {
                overflow: hidden;
                white-space: initial;
                max-height: 68px;
                
                // display: -webkit-box;
                // -webkit-line-clamp: 2;
                // -webkit-box-orient: vertical;
                text-overflow: ellipsis;

                // @include sm {
                //     margin-bottom: 40px;
                // }
            }

            img {
                width: 156px;
                height: 52px;
                padding: 10px;
                margin-left: 10px;
                background-color: #ffffff;
                border-radius: 12px 0 0 12px;

                @include sm {
                    width: 108px;
                    height: 33px;
                    padding: 5px;
                    margin-top: 7px;

                    align-self: flex-end;
                }
            }
        }

        .details {
            font-size: 16px;
            line-height: 20px;

            color: #ffffff;
            background: #ff9534;

            @include sm {
                font-size: 13px;
                line-height: 16px;
            }
        }

        .description {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;

            padding: 0;
            margin: 8px;
            padding-bottom: 8px;
            margin-bottom: 0;

            height: 162px;
            white-space: normal;
            overflow-y: auto;
            color: #181818;

            @include sm {
                font-size: 13px;
                line-height: 16px;
            }

            h2 {
                font-size: 22px;
                line-height: 34px;

                @include sm {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
    }
}