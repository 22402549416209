.game9 {
    
    .placementContainer {
        display: flex;
        padding: 12px;

        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(12px);
        border-radius: 12px;
        transition: background-color 0.5s ease-out;

        overflow: hidden;

        .placementArea {
            display: flex;
        }

        .draggable {
            cursor: grab;

            height: 400px;
            background: #FFFFFF;
            margin: 0 6px;

            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(10px);
            border-radius: 12px;

            &:not(.dragging) {
                transition: 0.2s ease-out;
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &.completed {
            background-color: rgba(100, 100, 100, 0.1);

            .draggable {
                cursor: default;
                transition: box-shadow 1s !important;
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0);
            }
        }
    }

    img {
        pointer-events: none;
    }
}