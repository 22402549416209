/* family=Amatic+SC:wght@400;700&family=Quicksand:wght@400;700&display=swap */
/* amatic-sc-regular - latin */
@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('./amatic-sc-v24-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./amatic-sc-v24-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* amatic-sc-700 - latin */
@font-face {
    font-family: 'Amatic SC';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('./amatic-sc-v24-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./amatic-sc-v24-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* quicksand-regular - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
         url('./quicksand-v30-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./quicksand-v30-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* quicksand-700 - latin */
@font-face {
    font-family: 'Quicksand';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
         url('./quicksand-v30-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('./quicksand-v30-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}