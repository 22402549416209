@import "src/variables.scss";

.game  .swiper-pagination-progressbar.swiper-pagination {
    bottom: 0;
    top: auto;
    position: fixed;

    @include md {
        bottom: -100px;
    }
}

.gallery {

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Quicksand', sans-serif;
        font-size: 40px;
        color: #5E5E5E;

        img {
            height: 70px;
            width: 70px;
            margin-right: 10px;
            animation: rotateLoop 1500ms linear infinite;
        }
        
        &:after {
            content: ' .';
            animation: dots 1s steps(5, end) infinite;
        }
    }

    .swiper {
        font-family: 'Quicksand', sans-serif;

        .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
            width: unset;
            max-width: 90vw;

            .image {
                position: relative;
                display: flex;
                height: 100%;
                background: #FFFFFF;
            }

            img {
                display: block;
                margin: 0;
                padding: 12px;
                border-radius: 20px;
                transition: box-shadow .4s;
            }

            figcaption {
                position: absolute;
                bottom: 12px;
                left: 12px;
                right: 12px;
                padding: 12px;
                background: rgba(255, 255, 255, 0.5);

                word-break: break-word;
                word-wrap: break-word;
                hyphens: auto;
            }

            &.swiper-slide-active .image,
            &:hover .image {
                box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.2), inset 0px -2px 1px rgba(0, 0, 0, 0.2);
            }
        }
    }
}

@keyframes dots {
    0%,
    20% {
        color: #ffffff;
        text-shadow:
            .25em 0 0 #ffffff,
            .5em 0 0 #ffffff;
    }
    40% {
        color: currentcolor;
        text-shadow:
            .25em 0 0 #ffffff,
            .5em 0 0 #ffffff;
    }
    60% {
        text-shadow:
            .25em 0 0 currentcolor,
            .5em 0 0 #ffffff;
    }
    80%,
    100% {
        text-shadow:
            .25em 0 0 currentcolor,
            .5em 0 0 currentcolor;
    }
}