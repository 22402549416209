@use "sass:math";
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 480px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and desktops
$screen-lg-min: 1024px;

@mixin sm {
    @media (max-width: #{$screen-sm-min}) {
        @content;
    }
}

@mixin md {
    @media (max-width: #{$screen-md-min}) {
        @content;
    }
}

@mixin lg {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}

@mixin lg-min {
    @media (min-width: #{$screen-lg-min + 1}) {
        @content;
    }
}

@mixin aspect-ratio($width, $height) {
    aspect-ratio: math.div($width, $height);
    @supports not (aspect-ratio: 1/1) {
        width: 100vw;
        height: math.div($height, $width) * 100vw;

        max-height: 100vh;
        max-width: math.div($width, $height) * 100vh;
    }
}

$debug: true;
$debug: false;

@if $debug == true {
    html:after {
        position: fixed;
        height: 20px;

        opacity: .5;
        text-align: center;
        pointer-events: none;
        z-index: 999999;

        left: 10px;
        bottom: 10px;
        padding: 5px;
    
        @include lg {
            background: #ff0;
            content: "lg";
            color: #000
        }
    
        @include md {
            background: #00f;
            content: "md";
            color: #fff;
        }

        @include sm {
            background: red;
            content: "sm";
            color: #fff;
        }
    }
}