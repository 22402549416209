@import "src/variables.scss";

.game2 {

    &.game {
        background-size: cover;
    }

    .container svg {
        margin: 40px;
        max-height: 380px;

        @include sm {
            margin: 15px;
        }
    }

    .colorButtons {
        display: grid;
        justify-items: center;
        align-items: center;

        padding: 0;
        margin: 0 60px 60px;
        grid-template-columns: repeat(6, 80px);
        grid-template-rows: repeat(2, 80px);
        list-style: none;

        @include md {
            grid-template-columns: repeat(4, 80px);
            grid-template-rows: repeat(3, 80px);
        }

        @include sm {
            margin-top: 60px;
        }

        li {
            box-sizing: content-box;
            width: 60px;
            height: 60px;
            border-radius: 100%;

            cursor: pointer;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

            border: 0px solid transparent;
            transition: border 200ms ease-out;

            &.active {
                border: 10px solid #fff;
            }
        }
    }
}