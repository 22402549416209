@import "src/variables.scss";

@mixin back-button {
    position: absolute;
    top: 12px;

    height: 68px;
    width: 68px;

    box-shadow: 0px 4px 12px 0px #00000040;
    border-radius: 12px;
    padding: 4px;
}

.navigation {

    .back,
    .help {
        @include back-button;
    }

    .back {
        left: 12px;
        z-index: 25;

        @include sm {
            z-index: 20;
        }
    }

    .help {
        right: 12px;
    }
}

.player.viewport-button button {
    @include back-button;
    right: 12px;
    z-index: 25;

    img {
        padding: 12%;
    }
}

.overlay {
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    height: var(--vh);
    z-index: 5;
    background: linear-gradient(269.5deg, rgba(0, 0, 0, 0.5) 0.43%, rgba(0, 0, 0, 0) 50%);

    &.full {
        background: rgba(0, 0, 0, 0.35);
    }
}

.helpBoxContainer {
    z-index: 20;
}

.helpBox {
    $space: 12px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    opacity: 1;
    transition: .3s;

    position: absolute;
    top: $space;
    right: $space;

    min-height: calc(100vh - 24px);
    min-height: calc(var(--vh) - 24px);
    width: 320px;

    border-radius: 12px;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    padding: 24px;
    z-index: 20;

    @include sm {
        top: 0;
        right: 0;
        width: 100vw;
        min-height: 100vh;
        min-height: var(--vh);
    }

    .mute {
        padding: 0;
        margin-bottom: 24px;
    }

    .textContainer {
        width: 100%;
    }

    .arrow {
        display: block;
        height: 40px;
        width: 40px;
        margin: 15px auto;
        
        &.left {
            margin: 15px 0;
        }
    }

    .animation {
        position: relative;
        flex: 1 1 auto;
        width: 100%;

        video {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
}

button.button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Quicksand', sans-serif;
    font-size: 22px;
    line-height: 20px;
    font-weight: 700;
    text-align: left;

    height: 68px;
    width: 100%;
    border-radius: 6px;
    padding: 0 11px 0 24px;
    margin-top: 10px;

    color: #fff;
    background: #8EC165;

    &.orange {
        background: #F3B454;
    }

    &.white {
        color: #5E5E5E;
        background: rgba(0, 0, 0, 0.05);
    }

    img {
        width: 60px;
        height: 60px;
        animation: moveLeftAndBack 5s ease-in-out infinite;

        &.rotate {
            animation-name: rotate;
        }
    }
}

.fullscreen {
    display: none;

    @include md {
        display: block;
        position: fixed;
        bottom: 34px;
        right: 24px;

        width: 30px;
        height: 30px;
        background-color: transparent;
    }
}

@keyframes moveLeftAndBack {
    80% {
        transform: translateX(0);
    }
    90% {
        transform: translateX(-10px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes rotate {
    80% {
        transform: rotate(0deg);
    }
    98% {
        transform: rotate(-350deg);
    }
    100% {
        transform: rotate(-359deg);
    }
}