@use "sass:math";
@import "src/variables.scss";

.game11 {

    &.game {
        background-color: #ffffff;
        background-size: contain;
    }

    .placementArea {
        position: relative;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        .spots {
            display: flex;
            padding: 22% 7.8% 26.4% 8.2%;

            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
    
            .spot {
                flex: 1 0 0;
                height: 100%;
            }
        }

        .droppable {
            transition: opacity 500ms ease-out;
            width: 100%;
            height: 100%;
        }

        .wall {
            position: relative;
        }

        .duck {
            position: absolute;
            top: 43%;
            width: 10%;
            height: 10%;
            animation: duck-animation 7s forwards;
        }
    }

    .dragContainer {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        
        justify-items: center;
        align-items: center;

        margin-bottom: 60px;

        position: absolute;
        bottom: 0;

        @include md {
            position: relative;
            margin: 30px;
        }

        .spot {
            display: flex;
            width: 100px;
            height: 160px;
            margin-left: 12px;
            margin-right: 12px;

            @include md {
                width: 80px;
                height: 129px;
                margin-left: 5px;
                margin-right: 5px;
            }

            @include sm {
                width: auto;
                height: auto;
            }
        }

        .draggable {
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
            backdrop-filter: blur(10px);
            border-radius: 6px;
            overflow: hidden;
            transition: all 0.5s ease-in-out;

            &.dragging {
                box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
            }
        }
    }
}

@keyframes duck-animation {
    0% {
        opacity: 0;
        left: 48%;
    }
    15% {
        opacity: 1;
    }
    100% {
        left: 28%;
    }
}