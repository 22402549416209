.fade-appear,
.fade-enter {
    opacity: 0;
    pointer-events: none;
}

.fade-appear-active,
.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms;
    pointer-events: none;
}

.fade-appear-done,
.fade-enter-done,
.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 400ms;
    pointer-events: none;
}

.fade-exit-done {
    opacity: 0;
    pointer-events: none;
}

.page-enter {
    opacity: 0;
    transform: scale(1.3);
}

.page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 350ms, transform 500ms;
}

.page-exit {
    opacity: 1;
    //transform: scale(1);
}

.page-exit-active {
    opacity: 0;
    //transform: scale(0.7);
    transition: opacity 350ms, transform 500ms;
}

.page-exit,
.page-exit-active {
    position: absolute;
    top: 0;

    width: 100vw;
    height: 100vh;
    height: var(--vh);
}

.trans-zoom-exit-active {
    opacity: 0;
    transform: scale(2);
    transition: all 750ms;
}

$durations: 250, 500, 750, 3000;

@each $duration in $durations {
    .duration-#{$duration} {
        transition-duration: #{$duration}ms;
        transition-timing-function: linear;
    }
}