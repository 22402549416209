@use "sass:math";
@import "src/variables.scss";

.game10 {
    // $item-size: 68px;
    // $item-gap: 60px;

    $item-size: 5.7vmin;
    $item-gap: 4vmin;

    &.game {
        background-color: #ffffff;
        background-size: contain;
    }

    .container {
        overflow: hidden;
    }

    .gameContainer {
        display: flex;
        align-items: flex-end;

        @include lg {
            display: grid;
            // height: 80vh;
            justify-items: center;
            grid-template-rows: 50vh minmax(75px, 10vh);

            > button {
                grid-row-start: 2;
                
                width: 5vh;
                height: 5vh;

                min-width: 50px;
                min-height: 50px;
            }
        }

        @include sm {
            margin-bottom: 180px;
        }
    }

    .drops {
        position: relative;
        display: flex;
        pointer-events: none;
        flex-grow: 1;

        @include lg-min {
            min-height: 50vh;
        }

        .drop {
            position: absolute;
            width: $item-size;
            height: $item-size;
            transition: all 750ms ease-in-out;
            transition-property: opacity, top;
            opacity: 0;

            @for $i from 1 through 8 {
                &.spot-#{$i} {
                    left: calc(($i - 1) * ($item-size + $item-gap));
                }
            }
        }
    }

    .catchAreaContainer {
        display: flex;
        flex-direction: column;
        margin: 0 $item-gap;
        align-self: stretch;
        grid-row-start: 1;
        grid-column: 1 / span 2;

        .catchArea {
            display: flex;

            > div {
                width: $item-size;
                height: $item-size;

                &:not(:first-child) {
                    margin-left: $item-gap;
                }
            }

            .carrier {
                cursor: e-resize;
                width: 300%;
                height: 470%;

                margin-left: -100%;
                margin-top: -285%;
            }

            > div:not(.post) {
                pointer-events: none;
                position: relative;
                width: $item-size;
                height: $item-size;

                &::after {
                    content: "";
                    display: block;
                    width: 18%;
                    height: 18%;
                    margin: math.div(100% - 18, 2);
                    border-radius: 100%;
                    background-color: #C4C4C4;
                }
            }
        }
    }

    .gameContainer button {
        width: $item-size;
        height: $item-size;

        background: #EFEFEF;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(10px);

        border-radius: math.div($item-size, 5.6);
        padding: math.div($item-size, 17);
    }

    .foundCounter {
        display: flex;

        position: fixed;
        padding: 12px;

        right: 24px;
        bottom: 24px;

        width: 312px;
        height: 60px;

        @include sm {
            right: 0;
            bottom: 0;
            width: 100vw;
            height: 140px;
            padding-bottom: 85px;
        }

        background: rgba(0, 0, 0, 0.05);
        border-radius: 12px;

        .letterContainer {
            position: relative;
            flex-grow: 1;

            .letter {
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;

                animation-duration: 1s;
                animation-timing-function: ease-in-out;
                animation-fill-mode: forwards;

                &.found {
                    animation-name: letterCatchedAnim;
                }
    
                &.missing {
                    animation-name: letterLostAnim;
                }
            }
        }
    }
}

@keyframes letterCatchedAnim {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes letterLostAnim {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(100%);
    }
}